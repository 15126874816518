import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Media } from "reactstrap";
import classNames from "classnames";
import Drawer from "../../../../../components/Drawer";
import Loading from "../../../../../components/Loaders/Loading";
import Clocks from "./Clocks";
import TimesheetHistory from "./History";
import Schedule from "./Schedule";
import { useHistory, useSchedule, useWorkSummaryHistory } from "./api";
import { getUploadUrl } from "../../../../../utils/helpers/upload";
import { initials } from "../../../../../utils/helpers/string";
import { dateTimeFormat } from "../../../../../utils/helpers/date";
import "./style.scss";

function History({
	selected,
	close,
	open,
	user,
	canViewHistory,
	canViewOriginalClocks,
	isMyTimesheet = false
}) {
	const { t } = useTranslation();
	const { loading, data, getWorkSummary } = useWorkSummaryHistory();
	const {
		loading: loadingHistory,
		data: historyData,
		totalItems,
		getHistory,
	} = useHistory();
	const { 
		data: events,
		loading: loadingEvents,
		getEvents
	} = useSchedule();

	const [menu, setMenu] = useState(selected?.menu || "original-clocks");

	const clocks = useMemo(() => {
		let clocks = [];

		data?.forEach((workSummary) =>
			workSummary?.shifts?.forEach((shift) =>
				clocks.push(shift?.clocks?.map((clock) => ({
					...clock,
					project: shift?.project,
					location: shift?.location,
					job: shift?.job,
					user: workSummary?.user
				})
				))),
		);

		return clocks.flat();
	}, [data]);

	const content = useMemo(() => {
		switch (menu) {
			case "original-clocks":
				return <Clocks clocks={clocks} />;
			case "schedule": 
				return <Schedule data={events} />
			case "history":
			default:
				return (
					<TimesheetHistory
						data={historyData}
						loading={loading}
						totalItems={totalItems}
					/>
				);
		}
	}, [menu, clocks, historyData, events, loading, totalItems]);

	useEffect(() => {
		const controller = new AbortController();
		if (user?.id) {
			if (menu === "original-clocks") {
				getWorkSummary(
					dateTimeFormat(selected?.date, "YYYY-MM-DD"),
					user.id,
					controller,
				);
			} else if (menu === "schedule") {
				const endpoint = isMyTimesheet 
					? "/my/schedules/events"
					: `/schedules/${user.id}/events`;
				
				getEvents(
					endpoint, 
					dateTimeFormat(selected?.date, "YYYY-MM-DD"),
					controller
				);
			} else {
				getHistory(
					dateTimeFormat(selected?.date, "YYYY-MM-DD"),
					user.id,
					controller,
				);
			}
		}
		return () => controller.abort();
	}, [getWorkSummary, getHistory, getEvents, selected, user?.id, menu, isMyTimesheet]);

	return (
		<Drawer
			title={
				<>
					<Media className="align-items-center px-3 pb-3">
						{user?.image ? (
							<img
								src={getUploadUrl(user?.image)}
								className="avatar rounded-circle mr-"
								width={37}
								height={37}
								alt=""
							/>
						) : (
							<div
								className="avatar rounded-circle d-flex align-items-center bg-muted"
								style={{ cursor: "auto" }}
							>
								<h3 className="text-white mb-0">
									{initials(user)}
								</h3>
							</div>
						)}

						<div className="ml-2 d-none d-block">
							<span className="mb-0 text-sm font-weight-bold">
								{`${user?.firstName} ${user?.lastName}`}
							</span>

							<div className="text-muted text-xs font-weight-400">
								{dateTimeFormat(selected?.date, "ddd, MMM DD")}
							</div>
						</div>
					</Media>

					<div className="d-flex align-items-center justify-content-center position-absolute bg-white w-100 text-xs border-top pt-3">
						{canViewOriginalClocks && (
							<div
								className={classNames(
									"cursor-pointer mr-3 px-3 py-2 font-weight-600",
									menu === "original-clocks" &&
									"text-primary border-primary border-bottom",
								)}
								onClick={() => setMenu("original-clocks")}
							>
								{t("original-clocks")}
							</div>
						)}
						{canViewHistory && (
							<div
								className={classNames(
									"cursor-pointer mr-3 px-3 py-2 font-weight-600",
									menu === "history" &&
									"text-primary border-primary border-bottom",
								)}
								onClick={() => setMenu("history")}
							>
								{t("history")}
							</div>
						)}

						{canViewHistory && (
							<div
								className={classNames(
									"cursor-pointer mr-3 px-3 py-2 font-weight-600",
									menu === "schedule" &&
									"text-primary border-primary border-bottom",
								)}
								onClick={() => setMenu("schedule")}
							>
								{t("schedule")}
							</div>
						)}
					</div>
				</>
			}
			open={open}
			close={close}
			className="history-drawer"
		>
			{loading || loadingHistory || loadingEvents ? (
				<div className="py-4 d-flex align-items-center justify-content-center">
					<Loading />
				</div>
			) : (
				content
			)}
		</Drawer>
	);
}

export default History;
