import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Button from "../../../../../components/Button";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { ProfileContext } from "../../../context";
import CalculationGroupBreaks from "../../../../Settings/PayPolicies/CalculationGroup/Form/Content/Breaks";
import {initialValues} from "../../../../Settings/PayPolicies/CalculationGroup/Form/initialValues";

function Breaks({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);
	const { changeCalculationGroupRules: profileSubmit } = useProfileApi();
	const { changeCalculationGroupRules: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		(data) => {
			submit(profileSubmit, peopleSubmit, data, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		console.log("initialValues", { breaks: user?.calculationGroupRules?.breaks || initialValues.breaks});
		form.setFieldsValue({ breaks: user?.calculationGroupRules?.breaks || initialValues.breaks });
	}, [user?.calculationGroupRules, form]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm([], submittingError);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<CalculationGroupBreaks disabled={false} />

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default Breaks;
